import { default as Research1dwxNpS3l6Meta } from "/usr/src/app/pages/Research.vue?macro=true";
import { default as aboutKJAmqyq7bDMeta } from "/usr/src/app/pages/about.vue?macro=true";
import { default as contact_45usAdrVxg0OvLMeta } from "/usr/src/app/pages/contact-us.vue?macro=true";
import { default as faqsyEOqTc8VH9Meta } from "/usr/src/app/pages/faqs.vue?macro=true";
import { default as how_45hoap_45worksWrsr72Fp8NMeta } from "/usr/src/app/pages/how-hoap-works.vue?macro=true";
import { default as indexgw2ikJsyhcMeta } from "/usr/src/app/pages/index.vue?macro=true";
import { default as _91slug_93oBZfGwRb8SMeta } from "/usr/src/app/pages/library/[slug].vue?macro=true";
import { default as indexFQZGrnJt97Meta } from "/usr/src/app/pages/library/index.vue?macro=true";
import { default as chartered_45accountantsbZwoARMPJyMeta } from "/usr/src/app/pages/partners/chartered-accountants.vue?macro=true";
import { default as pricingpYwygy9MPwMeta } from "/usr/src/app/pages/pricing.vue?macro=true";
import { default as privacy_45policy1IN0o4tDn5Meta } from "/usr/src/app/pages/privacy-policy.vue?macro=true";
import { default as terms_45of_45service7SIG93izWuMeta } from "/usr/src/app/pages/terms-of-service.vue?macro=true";
export default [
  {
    name: Research1dwxNpS3l6Meta?.name ?? "Research",
    path: Research1dwxNpS3l6Meta?.path ?? "/Research",
    meta: Research1dwxNpS3l6Meta || {},
    alias: Research1dwxNpS3l6Meta?.alias || [],
    redirect: Research1dwxNpS3l6Meta?.redirect || undefined,
    component: () => import("/usr/src/app/pages/Research.vue").then(m => m.default || m)
  },
  {
    name: aboutKJAmqyq7bDMeta?.name ?? "about",
    path: aboutKJAmqyq7bDMeta?.path ?? "/about",
    meta: aboutKJAmqyq7bDMeta || {},
    alias: aboutKJAmqyq7bDMeta?.alias || [],
    redirect: aboutKJAmqyq7bDMeta?.redirect || undefined,
    component: () => import("/usr/src/app/pages/about.vue").then(m => m.default || m)
  },
  {
    name: contact_45usAdrVxg0OvLMeta?.name ?? "contact-us",
    path: contact_45usAdrVxg0OvLMeta?.path ?? "/contact-us",
    meta: contact_45usAdrVxg0OvLMeta || {},
    alias: contact_45usAdrVxg0OvLMeta?.alias || [],
    redirect: contact_45usAdrVxg0OvLMeta?.redirect || undefined,
    component: () => import("/usr/src/app/pages/contact-us.vue").then(m => m.default || m)
  },
  {
    name: faqsyEOqTc8VH9Meta?.name ?? "faqs",
    path: faqsyEOqTc8VH9Meta?.path ?? "/faqs",
    meta: faqsyEOqTc8VH9Meta || {},
    alias: faqsyEOqTc8VH9Meta?.alias || [],
    redirect: faqsyEOqTc8VH9Meta?.redirect || undefined,
    component: () => import("/usr/src/app/pages/faqs.vue").then(m => m.default || m)
  },
  {
    name: how_45hoap_45worksWrsr72Fp8NMeta?.name ?? "how-hoap-works",
    path: how_45hoap_45worksWrsr72Fp8NMeta?.path ?? "/how-hoap-works",
    meta: how_45hoap_45worksWrsr72Fp8NMeta || {},
    alias: how_45hoap_45worksWrsr72Fp8NMeta?.alias || [],
    redirect: how_45hoap_45worksWrsr72Fp8NMeta?.redirect || undefined,
    component: () => import("/usr/src/app/pages/how-hoap-works.vue").then(m => m.default || m)
  },
  {
    name: indexgw2ikJsyhcMeta?.name ?? "index",
    path: indexgw2ikJsyhcMeta?.path ?? "/",
    meta: indexgw2ikJsyhcMeta || {},
    alias: indexgw2ikJsyhcMeta?.alias || [],
    redirect: indexgw2ikJsyhcMeta?.redirect || undefined,
    component: () => import("/usr/src/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93oBZfGwRb8SMeta?.name ?? "library-slug",
    path: _91slug_93oBZfGwRb8SMeta?.path ?? "/library/:slug()",
    meta: _91slug_93oBZfGwRb8SMeta || {},
    alias: _91slug_93oBZfGwRb8SMeta?.alias || [],
    redirect: _91slug_93oBZfGwRb8SMeta?.redirect || undefined,
    component: () => import("/usr/src/app/pages/library/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexFQZGrnJt97Meta?.name ?? "library",
    path: indexFQZGrnJt97Meta?.path ?? "/library",
    meta: indexFQZGrnJt97Meta || {},
    alias: indexFQZGrnJt97Meta?.alias || [],
    redirect: indexFQZGrnJt97Meta?.redirect || undefined,
    component: () => import("/usr/src/app/pages/library/index.vue").then(m => m.default || m)
  },
  {
    name: chartered_45accountantsbZwoARMPJyMeta?.name ?? "partners-chartered-accountants",
    path: chartered_45accountantsbZwoARMPJyMeta?.path ?? "/partners/chartered-accountants",
    meta: chartered_45accountantsbZwoARMPJyMeta || {},
    alias: chartered_45accountantsbZwoARMPJyMeta?.alias || [],
    redirect: chartered_45accountantsbZwoARMPJyMeta?.redirect || undefined,
    component: () => import("/usr/src/app/pages/partners/chartered-accountants.vue").then(m => m.default || m)
  },
  {
    name: pricingpYwygy9MPwMeta?.name ?? "pricing",
    path: pricingpYwygy9MPwMeta?.path ?? "/pricing",
    meta: pricingpYwygy9MPwMeta || {},
    alias: pricingpYwygy9MPwMeta?.alias || [],
    redirect: pricingpYwygy9MPwMeta?.redirect || undefined,
    component: () => import("/usr/src/app/pages/pricing.vue").then(m => m.default || m)
  },
  {
    name: privacy_45policy1IN0o4tDn5Meta?.name ?? "privacy-policy",
    path: privacy_45policy1IN0o4tDn5Meta?.path ?? "/privacy-policy",
    meta: privacy_45policy1IN0o4tDn5Meta || {},
    alias: privacy_45policy1IN0o4tDn5Meta?.alias || [],
    redirect: privacy_45policy1IN0o4tDn5Meta?.redirect || undefined,
    component: () => import("/usr/src/app/pages/privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: terms_45of_45service7SIG93izWuMeta?.name ?? "terms-of-service",
    path: terms_45of_45service7SIG93izWuMeta?.path ?? "/terms-of-service",
    meta: terms_45of_45service7SIG93izWuMeta || {},
    alias: terms_45of_45service7SIG93izWuMeta?.alias || [],
    redirect: terms_45of_45service7SIG93izWuMeta?.redirect || undefined,
    component: () => import("/usr/src/app/pages/terms-of-service.vue").then(m => m.default || m)
  }
]
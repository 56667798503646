import components_plugin_KR1HBZs4kY from "/usr/src/app/.nuxt/components.plugin.mjs";
import unhead_KgADcZ0jPj from "/usr/src/app/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/usr/src/app/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import prefetch_client_5tzzN0oIVL from "/usr/src/app/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_OrkQhMqHci from "/usr/src/app/node_modules/@nuxt/image-edge/dist/runtime/plugin.mjs";
import chunk_reload_client_UciE0i6zes from "/usr/src/app/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import apollo_1ILFkzpYSr from "/usr/src/app/plugins/apollo.ts";
import fathom_client_HlKuHmwBFW from "/usr/src/app/plugins/fathom.client.ts";
import recaptcha_client_ElRG0N5AcO from "/usr/src/app/plugins/recaptcha.client.ts";
import sentry_client_shVUlIjFLk from "/usr/src/app/plugins/sentry.client.ts";
import vueLazyLoad_uspJSMIzFf from "/usr/src/app/plugins/vueLazyLoad.ts";
export default [
  components_plugin_KR1HBZs4kY,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  prefetch_client_5tzzN0oIVL,
  plugin_OrkQhMqHci,
  chunk_reload_client_UciE0i6zes,
  apollo_1ILFkzpYSr,
  fathom_client_HlKuHmwBFW,
  recaptcha_client_ElRG0N5AcO,
  sentry_client_shVUlIjFLk,
  vueLazyLoad_uspJSMIzFf
]
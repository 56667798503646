import { defineNuxtPlugin, useRuntimeConfig } from '#app'
import * as Sentry from '@sentry/vue'
import { Integrations } from '@sentry/tracing'

// https://github.com/nuxt-community/sentry-module/issues/358#issuecomment-1016983543
export default defineNuxtPlugin((nuxtApp) => {
  const config = useRuntimeConfig()
  const { vueApp } = nuxtApp

  Sentry.init({
    app: [vueApp],
    dsn: config.public.SENTRY_DSN,
    logErrors: false,
    environment: config.public.SENTRY_ENVIRONMENT || process.env.NODE_ENV,
    integrations: [
      new Integrations.BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(nuxtApp.$router),
      }),
    ],
    tracesSampleRate: config.public.SENTRY_TRACES_SAMPLE_RATE || 0.5,
  })

  return {
    provide: {
      sentry: Sentry,
    },
  }
})

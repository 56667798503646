<template>
  <component :is="components[props.icon]" :class="iconClass" />
</template>

<script setup lang="ts">
  import * as icons from './icons'

  const props = defineProps({
    icon: {
      type: String,
      required: true,
    },
    iconClass: {
      type: String,
    },
  })

  const components: Record<string, string> = { ...icons }
</script>

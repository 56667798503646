import { defineNuxtPlugin } from '#app'
import { VueReCaptcha } from 'vue-recaptcha-v3'

export default defineNuxtPlugin((nuxtApp) => {
  const { RECAPTCHA_SITE_KEY } = useRuntimeConfig().public

  if (RECAPTCHA_SITE_KEY) {
    nuxtApp.vueApp.use(VueReCaptcha, {
      siteKey: RECAPTCHA_SITE_KEY,
      loaderOptions: {
        autoHideBadge: true,
      },
    })
  }
})

import { defineNuxtPlugin } from '#app'
import { provideApolloClient } from '@vue/apollo-composable'
import { ApolloClient, from, HttpLink, InMemoryCache } from '@apollo/client/core'
import { onError } from '@apollo/client/link/error'
import { captureException } from '@sentry/core'
import { isPlainObject } from '@sentry/utils'

export default defineNuxtPlugin(() => {
  const config = useRuntimeConfig()

  const { KEYSTONE_API_URL = 'http://localhost:3001' } = config.public
  const uri = KEYSTONE_API_URL + '/api/graphql'

  const cache = new InMemoryCache()

  const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors)
      graphQLErrors.forEach((error) => captureException(isPlainObject(error) ? new Error(error.message) : error))
    if (networkError) captureException(networkError)
  })

  const httpLink = new HttpLink({ uri })

  const apolloClient = new ApolloClient({
    uri,
    ssrMode: true,
    link: from([errorLink, httpLink]),
    cache,
  })

  provideApolloClient(apolloClient)
})

<template>
  <div
    class="fixed inset-0 bg-black flex items-center transition-quick"
    :class="videoSelected ? 'bg-opacity-50' : 'bg-opacity-0 pointer-events-none'"
    @click="closeVideo">
    <div v-if="videoSelected" class="w-full z-10">
      <FocusTrap :active="videoSelected">
        <div class="px-8 lg:px-0 lg:w-3/5 mx-auto flex flex-col items-end justify-center">
          <button @click="closeVideo" class="mb-4">
            <HoapIcon icon="Cross" class="w-8 lg:w-12 h-8 lg:h-12 text-white" />
          </button>
          <div class="w-full aspect-16/9 relative bg-sky-blue-500">
            <iframe
              :src="`https://player.vimeo.com/video/${videoData?.id}?autoplay=true`"
              webkitallowfullscreen
              mozallowfullscreen
              allowfullscreen
              width="100%"
              height="100%"
              allow="autoplay" />
          </div>
          <div
            v-if="videoData?.title && videoData?.description"
            class="bg-white text-black rounded-b-4xl w-full p-6"
            @click.stop>
            <div class="text-2xl 2xl:text-4xl font-decorative">{{ videoData?.title }}</div>
            <div class="text-sm 2xl:text-base mt-4 font-light line-clamp-5">{{ videoData?.description }}</div>
          </div>
        </div>
      </FocusTrap>
    </div>
  </div>
</template>

<script setup lang="ts">
  import { FocusTrap } from 'focus-trap-vue'
  import { computed, ComputedRef } from 'vue'
  import { useLightbox } from '~/composables/useLightbox'

  const { videoData, closeVideo } = useLightbox()
  const videoSelected: ComputedRef<boolean> = computed(() => !!videoData.value?.id)
</script>

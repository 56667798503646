<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    class="stroke-current animate-spin"
    viewBox="0 0 82 82"
    preserveAspectRatio="xMidYMid"
    v-bind="$attrs">
    <circle
      cx="41"
      cy="41"
      fill="none"
      stroke-width="6"
      r="35"
      stroke-dasharray="164.93361431346415 56.97787143782138" />
  </svg>
</template>

import { Ref, ref } from 'vue'

type VideoType = { id: string; title?: string; description?: string; type?: string }
const videoData: Ref<VideoType | null> = ref(null)

export const useLightbox = () => {
  const showVideo = ({ id, title, description, type }: VideoType) =>
    (videoData.value = { id, title, description, type })

  const closeVideo = () => (videoData.value = null)

  return {
    videoData,
    showVideo,
    closeVideo,
  }
}

import { defineNuxtPlugin } from '#app'
import VueFathom from '@ubclaunchpad/vue-fathom'

export default defineNuxtPlugin((nuxtApp) => {
  const { FATHOM_SITE_ID } = useRuntimeConfig().public

  if (FATHOM_SITE_ID) {
    nuxtApp.vueApp.use(VueFathom, {
      siteID: FATHOM_SITE_ID,
      settings: {
        spa: 'history',
      },
    })
  }
})

<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 60.609 110.827">
    <g fill="none" stroke="currentColor" style="isolation: isolate">
      <path
        stroke-miterlimit="10"
        stroke-width="3px"
        d="M30.301 107.771V0M.03 80.396s30.271.61 30.271 30.432c0-29.7 30.271-30.432 30.271-30.432" />
    </g>
  </svg>
</template>
